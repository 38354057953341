<template>
  <div class="container">
    <div class="row">
      <div class="col-md-3 align-self-center">
        <h1 class="mb-3" style="margin-top: 30px;">Quem Somos</h1>
      </div>
     <div class="col-md-8 my-3">
        <p>
            Somos uma empresa tecnológica e com muita humanização, dedicada a segmentos do call center. Em constante inovação, com foco total nas atualizações de mercado. Sempre em busca do atendimento de excelência que resulta em resultados efetivos nas operações.
Nossos clientes já sabem: construímos uma reputação inquestionável por conta do compromisso com a ética e com desfechos positivos. Não por acaso, grandes empresas e corporações também são nossas parceiras habituais.
Evoluir e reinventar-se: não temos medo de mudar. Essa convicção nos move e ocorre porque atuamos com planejamento, nunca com improviso.
E mais: com pessoal treinado e motivado. É o nosso grande time de colaboradores que muito nos orgulha pela garra, pela disciplina e pela proatividade. É precioso constatar que, com trabalho bem feito, todos os partícipes saem satisfeitos.

        </p>
        <hr>
        <p class="mt-4"> Endereço Matriz:&nbsp;<br>R. Antônio Alves, 29-16 - Bauru - São Paulo - CEP 17012-431 Endereço&nbsp;<br><br>Filial 1:&nbsp;<br>Rua Eduardo Ruiz Cobo, 0-1856 - Pederneiras - SP - CEP 17280-000&nbsp;<br><br>Endereço Filial 2:&nbsp;<br>Rua Antônio Alves, 34-27 - Bauru - SP - CEP 17012-431 </p>
        <a class="btn btn-link " href="tel:+55143235-0900"><i class="fa fa-phone fa-fw mx-1 fa-lg"></i>(xx) xxxx-xxxx</a><br>
        <a class="btn btn-link " href="mailto:xxxx@xxxx.com" target="_blank"><i class="fa fa-envelope-o fa-fw mx-1 fa-lg"></i>xxxx@xxxx.com</a>
      </div>
    </div>
  </div>
  
</template>

<script>

export default {
    name: "Termos",
     components: {
    },
    data: () => ({
        links: [
        ]
    }),
    methods: {
    },
    async created() {
        
    },
    computed: {
    }
};
</script>
